import ApiResult from "@/shared/ApiResult";
import SocketSubscriptionTopic from "@/shared/SocketSubscriptionTopic";
import SocketIOProvider from "@/socketIO/SocketIOProvider";
import store from "@/store";
import Player from "@/types/Player";
import PlayerBuzzer from "@/types/PlayerBuzzer";
import axios from "axios";
import { Subscription } from "rxjs";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

@Module({ namespaced: true, name: "playerSessionStore", store, dynamic: true })
export class PlayerSessionStore extends VuexModule {
  private _playerName = "";
  private _playerId = 0;
  private _gameId = 0;
  private _points = 0;
  private _players: Player[] = [];
  private _loaded = false;
  private _subscriptions: Subscription[] = [];
  private _question = "";
  private _answer = "";
  private _buzzered = false;

  public get loaded() {
    return this._loaded;
  }
  public get points() {
    return this._points;
  }
  public get gameId() {
    return this._gameId;
  }
  public get playerId() {
    return this._playerId;
  }
  public get playerName() {
    return this._playerName;
  }
  public get otherPlayers() {
    return this._players.filter((x) => x.id != this._playerId);
  }
  public get currentPlayer() {
    return this._players.find((x) => x.id == this._playerId);
  }
  public get question() {
    return this._question;
  }
  public get answer() {
    return this._answer;
  }
  public get buzzered() {
    return this._buzzered;
  }

  @Action
  public async Initialize(secret: string) {
    await this.loadMyUserInfo(secret);
    await this.loadPlayers();

    this.setLoaded();
  }

  @Action
  public async Subscribe() {
    let sub = SocketIOProvider.subscribe<Player>(
      SocketSubscriptionTopic.GAME_PLAYER_CHANGED,
      `GAME_${this.gameId}`
    ).subscribe(this.updatePlayer);
    this.addSubscription(sub);

    sub = SocketIOProvider.subscribe<string>(
      SocketSubscriptionTopic.GAME_UPDATE_QUESTION,
      `GAME_${this.gameId}`
    ).subscribe(this.setQuestion);
    this.addSubscription(sub);

    sub = SocketIOProvider.subscribe<string>(
      SocketSubscriptionTopic.GAME_UPDATE_ANSWER,
      `GAME_${this.gameId}`
    ).subscribe(this.setAnswer);
    this.addSubscription(sub);

    sub = SocketIOProvider.subscribe<string>(
      SocketSubscriptionTopic.GAME_RESET_QUESTION,
      `GAME_${this.gameId}`
    ).subscribe(this.resetQuestion);
    this.addSubscription(sub);

    sub = SocketIOProvider.subscribe<unknown>(
      SocketSubscriptionTopic.GAME_RESET_BUZZER,
      `GAME_${this.gameId}`
    ).subscribe(this.resetBuzzer);
    this.addSubscription(sub);
  }

  @Mutation
  private resetBuzzer() {
    this._buzzered = false;
  }

  @Mutation
  public Unsubscribe() {
    this._subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  @Mutation
  public setQuestion(q: string) {
    this._question = q;
  }
  @Mutation
  public setAnswer(a: string) {
    this._answer = a;
  }
  @Mutation
  public resetQuestion() {
    this._question = "";
    this._answer = "";
  }

  @Mutation
  private addSubscription(sub: Subscription) {
    this._subscriptions.push(sub);
  }

  @Action
  public async loadMyUserInfo(secret: string) {
    const result = await axios.get<
      ApiResult<Player & { buzzer: PlayerBuzzer }>
    >("/game/myUserInfo", {
      params: {
        secret,
      },
    });
    if (result.data.success && result.data.data) {
      const p = result.data.data;
      this.setGameId(p.gameId);
      this.setPlayerId(p.id);
      this.setName(p.name);
      this.setPoints(p.points);
      if (p.buzzer) {
        this.setBuzzered(true);
      }
    }
  }

  @Action({ rawError: true })
  public async loadPlayers() {
    const result = await axios.get<ApiResult<Player[]>>("/game/players", {
      params: {
        gameId: this.gameId,
      },
    });
    if (result.data.success) {
      this.setPlayers(result.data.data);
    } else {
      console.error(result.data.data);
    }
  }

  @Action
  public updatePlayer(player: Player) {
    const p = this._players.find((x) => x.id == player.id);
    if (p) {
      p.points = player.points;
    }
  }

  @Action
  public setPlayerAnswerAct(answer: string) {
    this.setPlayerAnswer(answer);
    axios.post("/game/player/setAnswer", {
      playerId: this.playerId,
      answer,
    });
  }

  @Action
  public buzzer() {
    this.setBuzzered(true);
    return axios.post<ApiResult<boolean>>("/game/player/buzzer", {
      playerId: this.playerId,
    });
  }

  @Mutation
  public setPlayerAnswer(answer: string) {
    const currentPlayer = this._players.find((x) => (x.id = this._playerId));
    if (currentPlayer) {
      currentPlayer.answer = answer;
    }
  }

  @Mutation
  private setBuzzered(buzzered: boolean) {
    this._buzzered = buzzered;
  }

  @Mutation
  private setLoaded() {
    this._loaded = true;
  }

  @Mutation
  private setPlayers(players: Player[]) {
    this._players = players;
  }

  @Mutation
  public setGameId(id: number) {
    this._gameId = id;
  }
  @Mutation
  public setPlayerId(id: number) {
    this._playerId = id;
  }

  @Mutation
  public setName(name: string) {
    this._playerName = name;
  }

  @Mutation
  public setPoints(points: number) {
    this._points = points;
  }
}

const playerSessionStore = getModule(PlayerSessionStore);
export default playerSessionStore;
