var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"mx-auto my-auto",attrs:{"width":_vm.width,"elevation":"10"}},[_c('v-card-title',{staticClass:"mx-auto justify-center"},[_c('h1',[_vm._v(" "+_vm._s(_vm.player.name)+" ")])]),_c('v-card-text',[_c('div',{staticClass:"GreenScreenContainer"})]),_c('v-card-actions',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row",style:(`opacity: ${_vm.myBuzzer ? '1' : 0}`)},[_c('div',{staticClass:"error--text text-center"},[_vm._v("BUZZER")]),_c('div',{class:[
          {
            'success--text': _vm.buzzerTimeRelative == 0,
            'error--text': _vm.buzzerTimeRelative > 0,
          },
          'text-center',
          'ml-4',
        ]},[_vm._v(" + "+_vm._s(_vm.buzzerTimeRelative)+" ms ")])]),_c('div',{staticClass:"d-flex flex-row"},[_c('v-icon',{staticClass:"mx-4"},[_vm._v("fas fa-medal")]),_c('h1',{staticClass:"mx-auto"},[_c('div',[_vm._v(_vm._s(_vm.player.points))])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }