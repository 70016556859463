import Vue from "vue";
import { ICustomComponentConfig } from "../CustomComponent/ICustomComponentConfig";
import ModalParameter from "./ModalParameter";
export type OffCanvasOptions = {
  title: string;
  customComponentConfig?: ICustomComponentConfig;
};

export default class Modal {
  private eventBus = new Vue();

  public get EventBus() {
    return this.eventBus;
  }

  public show(params: ModalParameter) {
    this.EventBus.$emit("showModal", params);
  }
}
