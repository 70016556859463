<template>
  <v-container v-if="loaded" class="d-flex flex-column">
    <div class="d-flex flex-row flex-wrap" style="gap: 2rem">
      <SpectatePlayerCard
        v-for="(p, i) in players"
        :key="i"
        :player="p"
        :width="playerCardWidth"
      />
    </div>
    <div class="d-flex flex-row flex-grow-1">
      <div class="d-flex flex-column">
        <v-sheet
          class="mx-auto my-10 d-flex QuestionSheet"
          rounded="xl"
          elevation="10"
        >
          <div class="d-flex flex-row my-auto w-100">
            <v-icon x-large class="mx-6" style="width: 1rem"
              >fas fa-question</v-icon
            >
            <v-fade-transition>
              <h1 v-if="question" class="mx-auto" style="width: fit-content">
                {{ question }}
              </h1>
            </v-fade-transition>
          </div>
        </v-sheet>
        <v-sheet
          class="mb-auto mx-auto d-flex QuestionSheet"
          rounded="xl"
          elevation="10"
        >
          <div class="d-flex flex-row my-auto w-100">
            <v-icon x-large class="mx-6" style="width: 1rem"
              >fas fa-exclamation</v-icon
            >
            <v-fade-transition>
              <h1
                v-if="answer"
                class="mx-auto success--text"
                style="width: fit-content"
              >
                {{ answer }}
              </h1>
            </v-fade-transition>
          </div>
        </v-sheet>
      </div>
    </div>
  </v-container>
</template>
<script lang="ts">
import Vue from "vue";
import SpectatePlayerCard from "./SpectatePlayerCard.vue";
import spectateStore from "./spectateStore";
export default Vue.extend({
  name: "SpectateView",
  components: { SpectatePlayerCard },
  props: {
    gameIdString: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      gameId: Number(this.gameIdString),
      playerCardWidth: "350px",
    };
  },
  async mounted() {
    if (this.$route.query.width) {
      this.playerCardWidth = `${this.$route.query.width}px`;
    }
    await spectateStore.Initialize(this.gameId);
    await spectateStore.Subscribe();
  },
  destroyed() {
    spectateStore.Unsubscribe();
  },
  computed: {
    loaded() {
      return spectateStore.loaded;
    },
    players() {
      return spectateStore.players;
    },
    question() {
      return spectateStore.question;
    },
    answer() {
      return spectateStore.answer;
    },
  },
});
</script>

<style lang="scss">
.GreenScreenContainer {
  background: rgb(0, 255, 0);
  border: 1px solid white;
  width: 100%;
  aspect-ratio: 16/9;
}
.QuestionSheet {
  min-height: 5rem;
  min-width: 50rem;
}
.Cast {
  background: red;
  border: 1px solid blue;
  width: 100%;
  aspect-ratio: 16/9;
}
</style>
