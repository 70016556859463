<template>
  <v-card :width="width" elevation="10" class="mx-auto my-auto">
    <v-card-title class="mx-auto justify-center">
      <h1>
        {{ player.name }}
      </h1>
    </v-card-title>
    <v-card-text>
      <div class="GreenScreenContainer"></div>
    </v-card-text>
    <v-card-actions class="d-flex flex-column">
      <div class="d-flex flex-row" :style="`opacity: ${myBuzzer ? '1' : 0}`">
        <div class="error--text text-center">BUZZER</div>
        <div
          :class="[
            {
              'success--text': buzzerTimeRelative == 0,
              'error--text': buzzerTimeRelative > 0,
            },
            'text-center',
            'ml-4',
          ]"
        >
          + {{ buzzerTimeRelative }} ms
        </div>
      </div>
      <div class="d-flex flex-row">
        <v-icon class="mx-4">fas fa-medal</v-icon>
        <h1 class="mx-auto">
          <div>{{ player.points }}</div>
        </h1>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Player from "@/types/Player";
import PlayerBuzzer from "@/types/PlayerBuzzer";
import Vue from "vue";
import { PropType } from "vue/types/v3-component-props";
import spectateStore from "./spectateStore";
export default Vue.extend({
  name: "SpectatePlayerCard",
  props: {
    player: {
      type: Object as PropType<Player>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    myBuzzer(): PlayerBuzzer | null {
      return (
        spectateStore.buzzers.find(
          (x: PlayerBuzzer) => x.playerId == this.player.id
        ) ?? null
      );
    },
    buzzerTimeRelative(): number | null {
      const firstBuzzer = [...spectateStore.buzzers].sort(
        (a, b) => a.timestamp - b.timestamp
      );
      if (firstBuzzer.length == 0) {
        return null;
      }
      if (!this.myBuzzer) {
        return null;
      }
      return this.myBuzzer?.timestamp - firstBuzzer[0].timestamp;
    },
  },
});
</script>
