<template>
  <v-app>
    <v-app-bar app color="primary">
      <h1>SchlauMeYeR</h1>
    </v-app-bar>

    <v-main>
      <modal-component>
        <router-view style="overflow-y: auto" />
      </modal-component>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import ModalComponent from "./plugins/modal/ModalComponent.vue";
import SocketIOProvider from "./socketIO/SocketIOProvider";

export default Vue.extend({
  components: { ModalComponent },
  name: "App",
  mounted() {
    const socketIoProv = SocketIOProvider;
    socketIoProv.connect();
  },
});
</script>
