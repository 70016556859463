<template>
  <div style="height: 100%; width: 100%" class="d-flex">
    <iframe
      style="width: 100%; height: 100%"
      src="https://player.twitch.tv/?meyer&parent=schlaumeyer.vserv2.splinti.de"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "HomeView",
});
</script>
