<template>
  <v-container>
    <v-hover v-for="(game, i) in games" :key="i">
      <template v-slot:default="{ hover }">
        <v-card
          @click="() => navigateToGame(game.id)"
          class="GameCard transition-swing"
          :elevation="hover ? 24 : 6"
        >
          <v-card-title>{{ $t("Game.GameNumber", [game.id]) }}</v-card-title>
          <v-card-text>{{
            $t("Game.CreatedAt", [$d(new Date(game.createdAt))])
          }}</v-card-text>
        </v-card>
      </template>
    </v-hover>
  </v-container>
</template>

<script lang="ts">
import ApiResult from "@/shared/ApiResult";
import Game from "@/types/Game";
import Vue from "vue";
export default Vue.extend({
  name: "AdminDashboard",
  data() {
    return {
      games: [] as Game[],
    };
  },
  methods: {
    navigateToGame(id: string): void {
      this.$router.push({
        name: "adminGame",
        params: {
          gameIdString: id.toString(),
        },
      });
    },
  },
  async mounted() {
    this.games = (
      await this.axios.get<ApiResult<Game[]>>("/admin/games")
    ).data.data;
  },
});
</script>

<style lang="scss">
.GameCard {
  width: fit-content;
  &:hover {
    cursor: pointer;
    transform: translate(-0.25rem, -0.25rem);
  }
}
</style>
