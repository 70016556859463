enum QuestionPointsBehaviour {
  SubtractPlayerPoints = "SubtractPlayerPoints",
  GivePointsToOthers = "GivePointsToOthers",
}
export function questionPointsBehaviourTranslated(
  question: QuestionPointsBehaviour
): string {
  switch (question) {
    case QuestionPointsBehaviour.GivePointsToOthers:
      return "Punkte den Gegnern gutschreiben";
    case QuestionPointsBehaviour.SubtractPlayerPoints:
      return "Punkte des Spieler reduzieren";
    default:
      return QuestionPointsBehaviour.GivePointsToOthers;
  }
}
export default QuestionPointsBehaviour;
