import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "@fortawesome/fontawesome-free/css/all.css";

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      dark: {
        primary: "#31a2b8",
        secondary: "#140047",
      },
    },
  },
});
