import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import SettingsView from "@/views/admin/settings/SettingsView.vue";
import GameView from "@/views/admin/GameView.vue";
import OverlayView from "@/views/admin/OverlayView.vue";
import SpectateView from "./spectate/SpectateView.vue";
import AdminDashboard from "@/views/admin/AdminDashboard.vue";

Vue.use(VueRouter);

const adminRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "adminIndex",
    component: AdminDashboard,
  },
  {
    path: "settings/:gameIdString",
    name: "adminSettings",
    component: SettingsView,
    props: true,
  },
  {
    path: "game/:gameIdString",
    name: "adminGame",
    component: GameView,
    props: true,
  },
  {
    path: "spectate/:gameIdString",
    name: "adminOverlay",
    component: SpectateView,
    props: true,
  },
];

export default adminRoutes;
