import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PlayView from "../views/player/PlayView.vue";
import AdminView from "@/views/admin/AdminView.vue";
import adminRoutes from "@/views/admin/adminRoutes";
import InviteView from "@/views/player/InviteView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/invite/:gameId/:secret/",
    name: "invite",
    component: InviteView,
    props: true,
  },
  {
    path: "/play/:id?",
    name: "play",
    component: PlayView,
    props: true,
  },
  {
    path: "/admin",
    component: AdminView,
    children: adminRoutes,
  },
  {
    path: "*",
    name: "*",
    component: HomeView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
