<template>
  <div class="w-100 h-100 d-flex">
    <v-dialog v-model="modal" :max-width="600">
      <v-card class="CustomModal__Wrapper">
        <v-toolbar class="CustomModal__Toolbar" dark color="primary">
          <v-btn icon dark @click="onAbort">
            <v-icon>fa fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="!showCustomComponent" class="pt-5 CustomModal__Text"
          >{{ text }}
        </v-card-text>
        <custom-component
          v-else
          :config="modalOptions.customComponentConfig"
          :key="customComponentKey"
          @dataChanged="dataChanged"
          @closeModal="closeModal"
          ref="customComponent"
          class="CustomModal__CustomComponent"
        />

        <v-card-actions class="CustomModal__Actions">
          <v-btn
            v-for="(btn, i) in leftBtns"
            :key="i"
            @click="clickBtn(btn)"
            :loading="btnsLoading"
            :color="btn.color"
          >
            <v-icon left dark v-if="leftIconVisible(btn)">
              {{ btn.icon }}
            </v-icon>
            {{ btn.text }}
            <v-icon right dark v-if="rightIconVisible(btn)">
              {{ btn.icon }}
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-for="(btn, i) in rightBtns"
            :key="i"
            @click="clickBtn(btn)"
            :loading="btnsLoading"
            :color="btn.color"
          >
            <v-icon left dark v-if="leftIconVisible(btn)">
              {{ btn.icon }}
            </v-icon>
            {{ btn.text }}
            <v-icon right dark v-if="rightIconVisible(btn)">
              {{ btn.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { v4 } from "uuid";
import Vue, { computed } from "vue";
import ButtonConfig from "./ButtonConfig";
import CustomComponent from "@/plugins/CustomComponent/CustomComponent.vue";
import ModalParameter from "./ModalParameter";

export default Vue.extend({
  name: "ModalComponent",
  components: {
    CustomComponent,
  },
  mounted() {
    this.$modal.EventBus.$on("showModal", (x: ModalParameter) => {
      this.showModal(x);
      this.modalOptions = x;
    });
  },
  methods: {
    closeModal() {
      this.onAbort();
    },
    leftIconVisible(btn: ButtonConfig) {
      return btn.icon && btn.iconPosition !== "right";
    },
    rightIconVisible(btn: ButtonConfig) {
      return btn.icon && btn.iconPosition === "right";
    },
    async clickBtn(btn: ButtonConfig) {
      this.btnsLoading = true;
      if (
        btn.validate &&
        this.$refs.customComponent &&
        (this.$refs.customComponent as any).validate
      ) {
        if ((this.$refs.customComponent as any).validate()) {
          if (btn.onClick) {
            const result = await btn.onClick(this.modalData);
            if (result) {
              this.modal = false;
            }
          } else {
            this.modal = false;
          }
        }
      } else {
        if (btn.onClick) {
          const result = await btn.onClick(this.modalData);
          if (result) {
            this.modal = false;
          }
        } else {
          this.modal = false;
        }
      }
      this.btnsLoading = false;
    },
    dataChanged(key: any, value: any) {
      this.modalData[key] = value;
    },
    showModal(x: ModalParameter) {
      this.title = x.title ?? "";
      this.text = x.text ?? "";
      this.modal = true;
      this.customComponentKey = v4();
    },
    async onConfirm() {
      if (this.showCustomComponent) {
        if (
          this.modalOptions.onConfirm &&
          (this.$refs.customComponent as any).validate
        ) {
          const validateResult = await (
            this.$refs.customComponent as any
          ).validate();
          if (validateResult) {
            this.modalOptions.onConfirm(this.modalData);
          } else {
            return false;
          }
        }
      } else {
        if (this.modalOptions.onConfirm) {
          this.modalOptions.onConfirm(this.modalData);
        }
      }
      return true;
    },
    onAbort() {
      this.modal = false;
      if (this.modalOptions.onAbort) {
        this.modalOptions.onAbort(this.modalData);
      }
    },
  },
  data() {
    return {
      btnsLoading: false,
      confirmButtonOverlay: false,
      alerts: [] as any[],
      title: "",
      text: "",
      modal: false,
      modalOptions: {} as ModalParameter,
      customComponentKey: v4(),
      modalData: {} as any,
    };
  },
  computed: {
    filteredButtons(): ButtonConfig[] {
      if (!this.modalOptions.buttons) {
        return [
          {
            text: "Ok",
            icon: "fa fa-check",
            color: "success",
            onClick: async () => {
              return await this.onConfirm();
            },
          },
          {
            text: "Abbrechen",
            icon: "fa fa-times",
            color: "error",
            onClick: () => {
              this.onAbort();
              return true;
            },
          },
        ];
      }
      return this.modalOptions.buttons;
    },
    leftBtns(): ButtonConfig[] {
      return this.filteredButtons.filter(
        (x) => x.btnPosition && x.btnPosition === "left"
      );
    },
    rightBtns(): ButtonConfig[] {
      return this.filteredButtons.filter(
        (x) => !x.btnPosition || x.btnPosition === "right"
      );
    },
    showCustomComponent(): boolean {
      return this.modalOptions.customComponentConfig !== undefined;
    },
  },
});
</script>

<style lang="scss">
.CustomModal__Dialog {
  max-height: 80vh;

  .CustomModal__Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .CustomModal__Toolbar {
      height: fit-content;
    }

    .CustomModal__Text {
      flex-grow: 2;
      overflow-y: auto;
    }

    .CustomModal__CustomComponent {
      flex-grow: 2;
      overflow-y: auto;
    }

    .CustomModal__Actions {
      height: 52px;
    }
  }
}
</style>
