<template>
  <v-container class="d-flex flex-column" style="gap: 1rem">
    <v-btn icon @click="$router.push({ name: 'adminGame' })">
      <v-icon> fas fa-arrow-left </v-icon>
    </v-btn>
    <edit-question />
    <game-questions />
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import EditQuestion from "./EditQuestion.vue";
import GameQuestions from "./GameQuestions.vue";
export default Vue.extend({
  components: { GameQuestions, EditQuestion },
  name: "SettingsView",
  props: {
    gameIdString: {
      type: String,
      required: true,
    },
  },
});
</script>
