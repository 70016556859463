enum QuestionType {
  TextAnswer = "TextAnswer",
  EstimateAnswer = "EstimateAnswer",
  Buzzer = "Buzzer",
}
export function questionTypeTranslated(question: QuestionType): string {
  switch (question) {
    case QuestionType.Buzzer:
      return "Buzzer Frage";
    case QuestionType.TextAnswer:
      return "Textfrage";
    case QuestionType.EstimateAnswer:
      return "Schätzfrage";
  }
}
export default QuestionType;
