import { Socket, io } from "socket.io-client";
import { Observable } from "rxjs";
import SocketSubscriptionTopic from "@/shared/SocketSubscriptionTopic";
class SocketIOProvider {
  private static Instance: SocketIOProvider;
  private Client: Socket;
  static getInstance(): SocketIOProvider {
    if (!SocketIOProvider.Instance) {
      SocketIOProvider.Instance = new SocketIOProvider();
    }
    return SocketIOProvider.Instance;
  }

  /**
   *
   */
  constructor() {
    let address = ":/";
    if (process.env.NODE_ENV == "development") {
      address = "localhost:3000/";
    }
    this.Client = io(address, {
      autoConnect: false,
      transports: ["websocket"],
      reconnection: true,
    });
  }

  connect(): void {
    this.Client.connect();
  }

  public subscribe<T>(topic: SocketSubscriptionTopic, channel: string) {
    this.Client.io.on("reconnect", () => {
      console.log("socket reconnected");

      this.Client.emit("SUBSCRIBE", topic, channel);
    });
    return new Observable<T>((subscriber) => {
      this.Client.emit("SUBSCRIBE", topic, channel);
      this.Client.on(topic, (payload: T) => {
        subscriber.next(payload);
      });
    });
  }
}

export default SocketIOProvider.getInstance();
