<template>
  <v-container
    class="h-100 d-flex justify-center align-center"
    v-if="!authenticated"
  >
    <v-col cols="3" class="d-flex flex-column">
      <v-form>
        <v-text-field
          v-model="key"
          solo
          :label="$t('Admin.Password')"
          clearable
          type="password"
          :loading="busy"
          @keypress.enter="onEnter"
          :rules="[error]"
        ></v-text-field>
        <v-btn elevation="2" class="mx-auto" @click="onEnter">{{
          $t("Admin.Login")
        }}</v-btn>
      </v-form>
    </v-col>
  </v-container>
  <router-view v-else />
</template>

<script lang="ts">
import Vue from "vue";
import LocalStorageKeys from "@/types/LocalStorageKeys";
import { AxiosResponse } from "axios";
import ApiResult from "@/shared/ApiResult";
export default Vue.extend({
  name: "AdminView",
  components: {},
  data() {
    return {
      key: "",
      authenticated: false,
      busy: false,
      error: "",
    };
  },
  async mounted() {
    this.key = localStorage.getItem(LocalStorageKeys.ADMIN_KEY) ?? "";
    this.onEnter();
  },
  methods: {
    async onEnter() {
      if (await this.validatePW()) {
        localStorage.setItem(LocalStorageKeys.ADMIN_KEY, this.key);
        this.axios.defaults.headers.common.Authorization = this.key;
        this.authenticated = true;
      } else {
        this.error = "Password invalid";
        this.key = "";
      }
    },
    async validatePW() {
      this.busy = true;
      this.error = "";
      try {
        const result = await this.axios.post<
          { key: string },
          AxiosResponse<ApiResult<boolean>>
        >("/admin/validate", {
          key: this.key,
        });
        return result.data.success && result.data.data;
      } catch (error) {
        return false;
      } finally {
        setTimeout(() => {
          this.busy = false;
        }, 1000 * 2);
      }
    },
  },
});
</script>
