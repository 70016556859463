<script lang="ts">
import Vue, { PropType, CreateElement, VNode } from "vue";
import { ICustomComponentConfig } from "./ICustomComponentConfig";
import { v4 as uuidv4 } from "uuid";

/**
 * This component dynamicly renders the component given by the config with the given propertes.
 * The Usecase would be the ability to configure a component with props, events and all in typescript.
 * For example when a setting for a component override is needed (plugins/modal)
 */
export default Vue.extend({
  name: "CustomComponent",
  props: {
    config: {
      type: Object as PropType<ICustomComponentConfig>,
      required: true,
    },
  },
  data() {
    return {
      key: uuidv4(),
    };
  },
  render(h: CreateElement): VNode {
    return h(this.config.vueConstructor, {
      ref: "renderedComponent",
      key: this.key,
      ...this.config.data,
      on: {
        closeModal: () => {
          this.$emit("closeModal");
        },
      },
    });
  },
  methods: {
    onShow() {
      if ((this.$refs.renderedComponent as any).onShow) {
        (this.$refs.renderedComponent as any).onShow();
      }
    },
    onHide() {
      if ((this.$refs.renderedComponent as any).onHide) {
        (this.$refs.renderedComponent as any).onHide();
      }
    },
  },
});
</script>
