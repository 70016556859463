<template>
  <v-container>
    <v-form>
      <v-text-field
        v-model="username"
        filled
        label="Benutzername"
      ></v-text-field>
      <v-text-field
        v-model="newUserKey"
        filled
        label="key"
        readonly
      ></v-text-field>
    </v-form>
    <div class="d-flex flex-row">
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="copy">
        <v-icon class="mr-2">fas fa-copy</v-icon>
        Spieler hinzufügen & Link Kopieren
      </v-btn>
    </div>
  </v-container>
</template>

<script lang="ts">
import { v4 } from "uuid";
import Vue from "vue";
import gameSessionStore from "../gameSessionStore";
export default Vue.extend({
  props: {
    gameId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newUserKey: v4(),
      username: "",
    };
  },
  methods: {
    async copy() {
      const rootLink = window.location.origin;
      const url = `${rootLink}/invite/${this.gameId}/${this.newUserKey}`;
      await this.axios.post("/admin/createInvite", {
        secret: this.newUserKey,
        name: this.username,
        gameId: this.gameId,
      });
      gameSessionStore.loadPlayers();
      navigator.clipboard.writeText(url);
      this.$emit("closeModal");
    },
  },
});
</script>
