import { PluginObject } from "vue";
import Modal from ".";

const plugin: PluginObject<unknown> = {
  install(vue, options) {
    vue.prototype.$modal = new Modal();
  },
};

export default plugin;
