import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";

import modal from "@/plugins/modal/plugin";

import "@/css/global.scss";
import i18n from "./i18n";

Vue.config.productionTip = false;

axios.defaults.baseURL = "/api";

Vue.use(VueAxios, axios);

Vue.use(modal);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
