enum SocketSubscriptionTopic {
  GAME_PLAYER_CHANGED = "GAME_PLAYER_CHANGED",
  GAME_UPDATE_QUESTION = "GAME_UPDATE_QUESTION",
  GAME_UPDATE_ANSWER = "GAME_UPDATE_ANSWER",
  GAME_RESET_QUESTION = "GAME_RESET_QUESTION",
  GAME_RESET_BUZZER = "GAME_RESET_BUZZER",
  ADMIN_PLAYER_ANSWER_UPDATE = "ADMIN_PLAYER_ANSWER_UPDATE",
  ADMIN_PLAYER_BUZZERED = "ADMIN_PLAYER_BUZZERED",
}

export default SocketSubscriptionTopic;
