<template>
  <v-container class="h-100 d-flex justify-center align-center">
    <div class="d-flex flex-row justify-content-center align-items-center">
      <v-icon x-large class="mx-auto" color="primary" v-if="loading"
        >fas fa-spinner fa-spin fa-2xl</v-icon
      >
      <div v-else>Irgendetwas ist schiefgelaufen.</div>
    </div>
  </v-container>
</template>
<script lang="ts">
import ApiResult from "@/shared/ApiResult";
import LocalStorageKeys from "@/types/LocalStorageKeys";
import Vue from "vue";
export default Vue.extend({
  name: "InviteView",
  props: {
    gameId: {
      type: String,
      required: true,
    },
    secret: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    try {
      const result = await this.axios.post<ApiResult<boolean>>(
        "/game/validateInvite",
        {
          gameId: this.gameId,
          secret: this.secret,
        }
      );
      if (result.data.success && result.data.data) {
        localStorage.setItem(LocalStorageKeys.GAME_ID, this.gameId);
        localStorage.setItem(LocalStorageKeys.PLAYER_SECRET, this.secret);
        this.$router.push({
          name: "play",
        });
      } else {
        this.loading = false;
      }
    } catch (error) {
      this.loading = false;
    }
  },
});
</script>
