import store from "@/store";
import GameQuestion from "@/types/GameQuestion";
import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

@Module({ namespaced: true, name: "questionEditStore", store, dynamic: true })
export class QuestionEditStore extends VuexModule {
  private _selectedQuestion?: GameQuestion | null;

  public get selectedQuestion() {
    return this._selectedQuestion;
  }

  @Mutation
  public selectQuestion(q: GameQuestion) {
    this._selectedQuestion = q;
  }

  @Mutation
  public clearSelection() {
    this._selectedQuestion = null;
  }
}

const questionEditStore = getModule(QuestionEditStore);
export default questionEditStore;
