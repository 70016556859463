<template>
  <v-container style="height: 100%" class="d-flex flex-column">
    <v-fade-transition>
      <v-card width="300px" class="mx-auto my-auto" v-if="hasError == true">
        <v-card-title>
          <div>Etwas ist schiefgelaufen.</div>
        </v-card-title>
        <v-card-text>
          <p>
            Da scheint etwas nicht korrekt zu sein. Bitte öffne den
            Einladungslink erneut.
          </p>
        </v-card-text>
      </v-card>
      <game-component v-else-if="hasError == false" />
    </v-fade-transition>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import GameComponent from "./GameComponent.vue";
import LocalStorageKeys from "@/types/LocalStorageKeys";

export default Vue.extend({
  components: { GameComponent },
  data() {
    return {
      name: "",
      hasJoined: false,
      hasError: null as null | boolean,
    };
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  mounted() {
    const gameId = localStorage.getItem(LocalStorageKeys.GAME_ID);
    const secret = localStorage.getItem(LocalStorageKeys.PLAYER_SECRET);
    if (!(gameId && secret)) {
      this.hasError = true;
    } else {
      this.hasError = false;
    }
  },
});
</script>
