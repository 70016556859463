<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-card elevation="2">
    <v-card-title class="d-flex flex-row">
      <span> Game Questions </span>
      <v-switch
        readonly
        class="ml-auto"
        :value="fastDelete"
        label="Fast-Delete"
        @click="enableFastDelete"
      ></v-switch>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :server-items-length="totalCount"
        :options.sync="filterOptions"
        :loading="loading"
        :headers="headers"
        :items="questions"
      >
        <template #item.wrongAnswerPointsBehaviour="{ item }">
          {{
            questionPointsBehaviourTranslated(item.wrongAnswerPointsBehaviour)
          }}
        </template>
        <template #item.type="{ item }">
          {{ questionTypeTranslated(item.type) }}
        </template>
        <template #item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import GameQuestion from "@/types/GameQuestion";
import questionEditStore from "./QuestionEditStore";
import RootEvents from "@/types/RootEvents";
import ApiResult from "@/shared/ApiResult";
import { questionPointsBehaviourTranslated } from "@/shared/QuestionPointsBehaviour";
import { questionTypeTranslated } from "@/shared/QuestionType";
export default Vue.extend({
  name: "GameQuestions",
  data() {
    return {
      fastDelete: false,
      totalCount: 0,
      filterOptions: {},
      questions: [] as GameQuestion[],
      loading: true,
      headers: [
        {
          text: "Typ",
          value: "type",
        },
        {
          text: "Frage",
          value: "question",
        },
        {
          text: "Punkte",
          value: "correctAnswerPoints",
        },
        {
          text: "Punkte f. Gegner",
          value: "wrongAnswerPoints",
        },
        {
          text: "Punkteverhalten",
          value: "wrongAnswerPointsBehaviour",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.$root.$on(
      RootEvents.GameQuestionsRefetch,
      this.fetchQuestions.bind(this)
    );
  },
  beforeDestroy() {
    this.$root.$off(
      RootEvents.GameQuestionsRefetch,
      this.fetchQuestions.bind(this)
    );
  },
  watch: {
    filterOptions: {
      handler() {
        this.fetchQuestions();
      },
      deep: true,
    },
  },
  methods: {
    questionPointsBehaviourTranslated: questionPointsBehaviourTranslated,
    questionTypeTranslated: questionTypeTranslated,
    enableFastDelete() {
      if (this.fastDelete) {
        this.fastDelete = false;
      } else {
        this.$modal.show({
          title: "Fast-Delete aktivieren?",
          text: "Mit Fast-Delete werden Einträge direkt gelöscht, ohne vorherige bestätigung. Soll Fast-Delete aktiviert werden?",
          buttons: [
            {
              icon: "fas fa-check",
              text: "",
              color: "success",
              onClick: () => {
                this.fastDelete = true;
                return true;
              },
            },
            {
              icon: "fas fa-times",
              text: "",
              color: "error",
            },
          ],
        });
      }
    },
    async fetchQuestions() {
      this.loading = true;
      const result = await this.axios.get<
        ApiResult<{ result: GameQuestion[]; totalCount: number }>
      >("/admin/questions", {
        params: this.filterOptions,
      });
      this.questions = result.data.data.result;
      this.totalCount = result.data.data.totalCount;
      this.loading = false;
    },
    editItem(item: GameQuestion) {
      questionEditStore.selectQuestion(item);
    },
    async deleteItemClick(item: GameQuestion): Promise<boolean> {
      try {
        const apiResult = await this.axios.delete<ApiResult<boolean>>(
          "/admin/question",
          {
            data: {
              id: item.id,
            },
          }
        );
        if (apiResult.data.success) {
          await this.fetchQuestions();
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
      }
      return false;
    },
    deleteItem(item: GameQuestion) {
      if (this.fastDelete) {
        this.deleteItemClick(item);
        return;
      }
      this.$modal.show({
        title: "Element löschen?",
        text: `Soll die Frage '${item.question}' wirklich gelöscht werden?`,
        buttons: [
          {
            text: "Löschen",
            color: "error",
            icon: "fas fa-trash",
            onClick: async () => {
              return this.deleteItemClick(item);
            },
          },
          {
            text: "Abbrechen",
            color: "",
            icon: "",
            onClick: () => true,
          },
        ],
      });
    },
  },
});
</script>
