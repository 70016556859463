<template>
  <v-card outlined shaped class="PlayerCard" elevation="10">
    <v-card-title class="d-flex flex-row justify-content-center">
      <div class="mx-auto">
        {{ player.name }}
      </div>
      <v-icon
        style="position: absolute; right: 1rem"
        small
        @click="copyInviteLink"
      >
        fas fa-link
      </v-icon>
    </v-card-title>
    <v-card-text class="d-flex flex-column" style="gap: 0.5rem">
      <div class="d-flex flex-row">
        <h4 class="mx-auto">
          {{ player.points }}
        </h4>
        <font-awesome-icon
          style="cursor: pointer"
          :icon="editIcon"
          @click="editPoints"
        />
      </div>
      <div class="d-flex flex-row justify-space-around" style="gap: 0.5rem">
        <v-btn
          class="flex-grow-1"
          elevation="2"
          small
          color="success"
          @click="() => clickAnswer(true)"
          >{{ $t("Game.Question.TRUE") }}</v-btn
        >
        <v-btn
          class="flex-grow-1"
          elevation="2"
          small
          color="error"
          @click="() => clickAnswer(false)"
          >{{ $t("Game.Question.FALSE") }}</v-btn
        >
      </div>
      <v-text-field
        label="Antwort"
        readonly
        :value="player.answer"
        hide-details=""
      ></v-text-field>
      <v-fade-transition>
        <div v-if="myBuzzer">
          <div class="d-flex flex-column">
            <div class="error--text">BUZZERED</div>
            <div
              :class="{
                'success--text': buzzerTimeRelative == 0,
                'error--text': buzzerTimeRelative > 0,
              }"
            >
              + {{ buzzerTimeRelative }} ms
            </div>
          </div>
        </div>
      </v-fade-transition>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Player from "@/types/Player";
import Vue from "vue";
import { PropType } from "vue/types/v3-component-props";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import gameSessionStore from "@/views/admin/gameSessionStore";
import PlayerBuzzer from "@/types/PlayerBuzzer";
export default Vue.extend({
  name: "PlayerCard",
  props: {
    player: {
      type: Object as PropType<Player>,
      required: true,
    },
  },
  data() {
    return {
      editIcon: faPenToSquare,
      showTooltip: false,
    };
  },
  methods: {
    clickAnswer(isCorrectAnswer: boolean) {
      this.$emit("clickAnswer", isCorrectAnswer, this.player.id);
    },
    async editPoints() {
      const res = Number(
        window.prompt(
          this.$t("Game.Player.SetPoints").toString(),
          this.player.points.toString()
        )
      );
      if (!isNaN(res)) {
        await this.axios.post("/admin/setPoints", {
          playerId: this.player.id,
          points: res,
        });
      }
    },
    copyInviteLink() {
      const rootLink = window.location.origin;
      const url = `${rootLink}/invite/${this.player.gameId}/${this.player.secret}`;
      navigator.clipboard.writeText(url);
    },
  },
  computed: {
    myBuzzer(): PlayerBuzzer | null {
      return (
        gameSessionStore.buzzers.find(
          (x: PlayerBuzzer) => x.playerId == this.player.id
        ) ?? null
      );
    },
    buzzerTimeRelative(): number | null {
      const firstBuzzer = [...gameSessionStore.buzzers].sort(
        (a, b) => a.timestamp - b.timestamp
      );
      if (firstBuzzer.length == 0) {
        return null;
      }
      if (!this.myBuzzer) {
        return null;
      }
      return this.myBuzzer?.timestamp - firstBuzzer[0].timestamp;
    },
  },
});
</script>

<style lang="scss">
.PlayerCard {
  width: 15rem;
  height: 17rem;
}
</style>
