<template>
  <div
    class="w-100 d-flex flex-wrap flex-column align-center justify-center"
    style="gap: 0.5rem"
  >
    <v-container>
      <v-card class="w-100">
        <v-card-title>
          <v-btn icon @click="$router.push({ name: 'adminIndex' })">
            <v-icon> fas fa-arrow-left </v-icon>
          </v-btn>
          <div>{{ $t("Game.Controls") }}</div>
          <v-spacer></v-spacer>
          <v-btn @click="inviteUser">
            <v-icon>fas fa-user-plus</v-icon>
          </v-btn>
          <v-tooltip>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                @click="copyOverlayLink"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>fas fa-window-maximize</v-icon>
              </v-btn>
            </template>
            <span>Overlay link kopieren</span>
          </v-tooltip>
          <v-btn class="ml-1" @click="$router.push({ name: 'adminSettings' })">
            <v-icon>fas fa-cogs</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
    </v-container>
    <v-container class="d-flex flex-row">
      <v-row>
        <v-col cols="4">
          <v-btn color="primary w-100" @click="btnpreviousQuestion">
            <v-icon class="justify-content-start">fa fa-arrow-left</v-icon>
            <div class="mx-auto">
              {{ $t("Game.PrevQuestion") }}
            </div>
          </v-btn>
        </v-col>
        <v-col cols="4">
          <div class="d-flex flex-column" style="gap: 1rem">
            <v-btn color="success w-100" @click="revealQuestion">{{
              $t("Game.RevealQuestion")
            }}</v-btn>
            <v-btn color="success w-100" @click="revealAnswer">{{
              $t("Game.RevealAnswer")
            }}</v-btn>
          </div>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary w-100" @click="btnnextQuestion">
            <div class="mx-auto">
              {{ $t("Game.NextQuestion") }}
            </div>
            <v-icon>fa fa-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <h4>{{ $t("Game.Question.Current") }}</h4>
    <div class="question-items d-flex justify-space-around">
      <template v-for="(q, i) in currentQuestions">
        <v-badge
          bordered
          :color="getQuestionColor(q)"
          :icon="getQuestionIcon(q)"
          overlap
          :key="i"
          :value="q != null"
        >
          <v-card
            class="d-flex flex-column"
            :class="{ question__null: q == null }"
            elevation="10"
          >
            <template v-if="q != null">
              <v-card-title
                class="justify-center"
                style="text-align: center; word-break: auto-phrase"
              >
                {{ q.question }}
              </v-card-title>
              <v-card-text
                class="d-flex w-100 flex-column flex-grow-1 justify-space-around align-start"
              >
                <div><strong>Antwort:</strong> {{ q.answer }}</div>
                <div>
                  <strong>Punkte für den Spieler:</strong>
                  {{ q.correctAnswerPoints }}
                </div>
                <div>
                  <strong>Punkte für andere Spieler:</strong>
                  {{ q.wrongAnswerPoints }}
                </div>
                <div>
                  <strong>Verhalten:</strong>
                  {{
                    questionPointsBehaviourTranslated(
                      q.wrongAnswerPointsBehaviour
                    )
                  }}
                </div>
              </v-card-text>
            </template>
          </v-card>
        </v-badge>
      </template>
    </div>
    <div
      class="w-100 d-flex flex-wrap align-center justify-center"
      style="gap: 0.5rem"
    >
      <PlayerCard
        v-for="player in players"
        :key="player.id"
        :player="player"
        @clickAnswer="clickAnswer"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import PlayerCard from "@/components/PlayerCard.vue";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import InviteUserModalVue from "./modals/InviteUserModal.vue";
import Player from "@/types/Player";
import gameSessionStore from "./gameSessionStore";
import GameQuestion from "@/types/GameQuestion";
import Game from "@/types/Game";
import { questionPointsBehaviourTranslated } from "@/shared/QuestionPointsBehaviour";
import QuestionType, { questionTypeTranslated } from "@/shared/QuestionType";
export default Vue.extend({
  name: "GameView",
  components: {
    PlayerCard,
  },
  props: {
    gameIdString: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      settingsIcon: faCogs,
    };
  },
  async mounted() {
    await gameSessionStore.Initialize(Number(this.gameIdString));
  },
  computed: {
    allQuestions(): (GameQuestion | null)[] {
      return [null, ...gameSessionStore.allQuestions, null];
    },
    currentQuestions(): (GameQuestion | null)[] {
      // index is 0, but 0 is definitely null the first question. This is fine.
      if (this.currentQuestionIndex == null) {
        return [null, null, this.allQuestions[1]];
      }
      return this.allQuestions.slice(
        this.currentQuestionIndex,
        this.currentQuestionIndex + 3
      );
    },
    players(): Player[] {
      return gameSessionStore.players;
    },
    game(): Game | null {
      return gameSessionStore.game;
    },
    currentQuestionIndex(): number | null {
      if (!this.game) {
        return null;
      }
      return this.game.currentQuestionIndex;
    },
  },
  methods: {
    questionPointsBehaviourTranslated,
    questionTypeTranslated,
    copyOverlayLink() {
      const res = this.$router.resolve({
        name: "adminOverlay",
        params: {
          gameIdString: this.gameIdString,
        },
      }).href;
      const rootLink = window.location.origin;
      const link = `${rootLink}${res}`;
      navigator.clipboard.writeText(link);
    },
    clickAnswer(isCorrectAnswer: boolean, playerId: number) {
      gameSessionStore.clickAnswer({
        gameId: Number(this.gameIdString),
        isCorrectAnswer,
        playerId,
      });
    },
    revealQuestion() {
      if (this.game) {
        gameSessionStore.revealQuestion();
      }
    },
    revealAnswer() {
      if (this.game) {
        gameSessionStore.revealAnswer();
      }
    },
    getQuestionColor(q: GameQuestion) {
      if (q == null) {
        return "";
      }
      switch (q.type) {
        case QuestionType.TextAnswer:
          return "success";
        case QuestionType.EstimateAnswer:
          return "warning";
        case QuestionType.Buzzer:
          return "error";
      }
    },
    getQuestionIcon(q: GameQuestion) {
      if (q == null) {
        return "";
      }
      switch (q.type) {
        case QuestionType.TextAnswer:
          return "fas fa-pen";
        case QuestionType.EstimateAnswer:
          return "fas fa-lightbulb";
        case QuestionType.Buzzer:
          return "fas fa-circle";
      }
    },
    btnpreviousQuestion() {
      if (this.currentQuestionIndex == null) {
        return;
      }
      if (this.currentQuestionIndex == 0) {
        gameSessionStore.setIndex(null);
        return;
      }
      gameSessionStore.setIndex(this.currentQuestionIndex - 1);
    },
    btnnextQuestion() {
      if (this.currentQuestionIndex == null) {
        gameSessionStore.setIndex(0);
        return;
      }
      if (this.currentQuestionIndex == this.allQuestions.length - 3) {
        return;
      }
      gameSessionStore.setIndex(this.currentQuestionIndex + 1);
    },
    updatePlayer(player: Player) {
      gameSessionStore.updatePlayer(player);
    },
    inviteUser(): void {
      this.$modal.show({
        title: "Benutzer einladen",
        buttons: [],
        customComponentConfig: {
          vueConstructor: InviteUserModalVue,
          data: {
            props: {
              gameId: 1,
            },
          },
        },
      });
    },
  },
});
</script>

<style lang="scss">
.question-items {
  margin: {
    top: 1rem;
    bottom: 1rem;
  }
  .v-badge {
    height: 17rem;
    width: 17rem;
    transition: all 0.2s ease-in-out;
    &.question__null {
      opacity: 0 !important;
    }
  }
  .v-badge:nth-child(odd) {
    opacity: 0.5;
    filter: blur(1px);
  }
  .v-badge:nth-child(1) {
    transform: translate3d(1rem, 1rem, -5rem) scale(0.7);
  }
  .v-badge:nth-child(3) {
    transform: translate3d(-1rem, 1rem, -5rem) scale(0.7);
  }
}
</style>
